import { db } from '@/firebase'
import { MODES } from '@/constants/toolPanelConstants'
import { TOKEN_COLLECTION } from '@/constants/collections'
import axios from 'axios'

const tokensRef = db.collection(TOKEN_COLLECTION)

export const isOwnerOf = async (tokenID, walletAddress, contract) => {
  try {
    const { methods } = contract
    const tokenOwner = await methods.ownerOf(tokenID).call()
    return tokenOwner === walletAddress
  } catch (e) {
    console.log('An error ocurred while getting user tokens.', e)
    throw e
  }
}

export const filterUserTokens = (tokens, walletAddress) => {
  const returnTokens = []
  if (tokens) {
    tokens.map((token) => {
      const { tokenID, from, to } = token
      const tokenId = Number(tokenID)
      if (
        !returnTokens.includes(tokenId) &&
        walletAddress.toLowerCase() === to
      ) {
        returnTokens.push(tokenId)
      } else if (
        returnTokens.includes(tokenId) &&
        walletAddress.toLowerCase() === from
      ) {
        for (var i = returnTokens.length; i--; ) {
          if (returnTokens[i] == tokenId) {
            returnTokens.splice(i, 1)
          }
        }
      }
    })

    returnTokens.sort(function (a, b) {
      return a - b
    })
  }

  return returnTokens
}

const retrieveTokensFromDB = async (tokens, projectSlug) => {
  const tokensFromDB = []

  if (tokens.length > 0) {
    for (let i = 0; i < tokens.length; i += 10) {
      const partialTokenIds = tokens.slice(i, i + 10)

      const snapshot = await tokensRef
        .where('projectSlug', '==', projectSlug)
        .where('tokenID', 'in', partialTokenIds)
        .get()

      if (!snapshot.empty) {
        tokensFromDB.push(...snapshot.docs.map((doc) => doc.data()))
      }
    }
  }
  return filterUniqueTokens(tokensFromDB)
}

const filterUniqueTokens = (tokens) => {
  const keys = ['tokenID', 'projectSlug']

  return tokens.filter(
    (
      (s) => (o) =>
        ((k) => !s.has(k) && s.add(k))(keys.map((k) => o[k]).join('|'))
    )(new Set())
  )
}

export const getUserTokensByContractAddress = async (
  contractAddress,
  projectSlug,
  walletAddress,
  page,
  offset
) => {
  try {
    const params = {
      module: 'account',
      action: 'tokennfttx',
      contractaddress: contractAddress,
      address: walletAddress,
      page,
      offset,
      sort: 'asc',
      apikey: process.env.VUE_APP_ETHERSCAN_API_KEY,
    }

    const { data } = await axios.get(
      process.env.VUE_APP_ETHERSCAN_API_BASE_URL,
      { params }
    )
    const userTokens = filterUserTokens(data.result, walletAddress)

    const tokensFromDB = await retrieveTokensFromDB(userTokens, projectSlug)
    return tokensFromDB
  } catch (e) {
    console.error('An error ocurred while getting user tokens.', e)
    throw e
  }
}

//returns only token ids from specified collection address (doesnt query firestore)
export const getUserTokensByCollectionAddress = async (
  contractAddress,
  walletAddress
) => {
  try {
    const params = {
      module: 'account',
      action: 'tokennfttx',
      contractaddress: contractAddress,
      address: walletAddress,
      apikey: process.env.VUE_APP_ETHERSCAN_API_KEY,
    }

    const { data } = await axios.get(
      process.env.VUE_APP_ETHERSCAN_API_BASE_URL,
      { params }
    )

    return filterUserTokens(data.result, walletAddress)
  } catch (e) {
    console.error('An error ocurred while getting user tokens.', e)
    throw e
  }
}

//returns firestore token objects from specific collection (sorted by tokenID asc)
export const getUserTokensByCollectionAddressFromDB = async (
  contractAddress,
  walletAddress,
  projectSlug
) => {
  try {
    const params = {
      module: 'account',
      action: 'tokennfttx',
      contractaddress: contractAddress,
      address: walletAddress,
      apikey: process.env.VUE_APP_ETHERSCAN_API_KEY,
    }

    const { data } = await axios.get(
      process.env.VUE_APP_ETHERSCAN_API_BASE_URL,
      { params }
    )

    const userTokens = filterUserTokens(data.result, walletAddress)
    const tokensFromDB = await retrieveTokensFromDB(userTokens, projectSlug)
    const sortedTokens = tokensFromDB.sort((a, b) =>
      a.tokenID < b.tokenID ? -1 : 1
    )
    return sortedTokens
  } catch (e) {
    console.error('An error ocurred while getting user tokens.', e)
    throw e
  }
}

export const getUserTokens = async (
  contractConfigs,
  walletAddress,
  page,
  offset
) => {
  const tokens = []
  for (const contractConfig of contractConfigs) {
    const tokensByContractAddress = await getUserTokensByContractAddress(
      contractConfig.address,
      contractConfig.projectSlug,
      walletAddress,
      page,
      offset
    )

    tokens.push(...tokensByContractAddress)
  }

  return tokens
}

export const getUserTokenBalance = async (walletAddress, contract) => {
  try {
    const { methods } = contract
    const tokenBalance = await methods.balanceOf(walletAddress).call()
    return tokenBalance
  } catch (e) {
    console.error('An error ocurred while getting user token balance.', e)
    throw e
  }
}

export async function getToken(projectSlug, tokenID) {
  let response
  let error
  try {
    const snapshot = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .where('tokenID', '==', parseInt(tokenID))
      .limit(1)
      .get()
    response = snapshot.docs[0].data()
  } catch (e) {
    error = e
  }

  return { response, error }
}

export async function getTokensByTxHash(projectSlug, txHash) {
  let response
  let error
  try {
    const snapshot = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .where('tx', '==', txHash)
      .get()
    response = snapshot.docs.map((doc) => doc.data())
  } catch (e) {
    error = e
  }

  return { response, error }
}

export async function getAllWordsByProperty(projectSlug, property) {
  try {
    const { docs } = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .get()
    let result = await docs.map((doc) => {
      const data = doc.data()
      return data[property]
    })
    const list = (result = result?.filter(
      (palabraRoundSignature) => palabraRoundSignature
    ))
    const uniques = [...new Set(result)]
    return { uniques, list }
  } catch (error) {
    console.error(error, 'Error getting words by property.')
    throw error
  }
}

export async function getAllTokensByProperty(projectSlug, property) {
  try {
    const { docs } = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .get()
    let result = await docs.map((doc) => {
      const data = doc.data()
      return data[property]
    })
    return { list: result, uniques: result }
  } catch (error) {
    console.error(error, 'Error getting tokens by property.')
    throw error
  }
}

export async function getProjectTokens(
  projectSlug,
  sortingField,
  sortingDirection
) {
  let response
  let error
  try {
    const snapshot = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .orderBy(sortingField, sortingDirection)
      .get()
    response = snapshot.docs.map((doc) => doc.data())
  } catch (e) {
    error = e
  }

  return { response, error }
}

export async function getProjectTokensTotal(projectSlug) {
  let response
  let error
  try {
    let allCounts

    const snapshot = await tokensRef
      .where('projectSlug', '==', projectSlug)
      .get()

    allCounts = snapshot.size
    response = allCounts
  } catch (e) {
    console.log(e)
    error = e
  }

  return { response, error }
}

export async function getProjectTokensTotalByMode(projectSlug) {
  let response
  let error
  try {
    let modeCounts = {
      1: {
        counts: 0,
        styles: {
          1: { counts: 0 },
          2: { counts: 0 },
          3: { counts: 0 },
          4: { counts: 0 },
          5: { counts: 0 },
          6: { counts: 0 },
        },
      },
      2: {
        counts: 0,
        styles: {
          1: { counts: 0 },
          2: { counts: 0 },
          3: { counts: 0 },
          4: { counts: 0 },
          5: { counts: 0 },
          6: { counts: 0 },
        },
      },
      3: {
        counts: 0,
        styles: {
          1: { counts: 0 },
          2: { counts: 0 },
          3: { counts: 0 },
          4: { counts: 0 },
          5: { counts: 0 },
          6: { counts: 0 },
        },
      },
      4: {
        counts: 0,
        styles: {
          1: { counts: 0 },
          2: { counts: 0 },
          3: { counts: 0 },
          4: { counts: 0 },
          5: { counts: 0 },
          6: { counts: 0 },
        },
      },
    }

    for (const modeKey in modeCounts) {
      try {
        const snapshot = await tokensRef
          .where('projectSlug', '==', projectSlug)
          .where('mode', '==', modeKey)
          .get()
        modeCounts[modeKey].counts = snapshot.size
      } catch (e) {
        console.log(e)
        error = e
      }
    }

    for (const modeKey in modeCounts) {
      for (const styleKey in modeCounts[modeKey].styles) {
        try {
          const snapshot = await tokensRef
            .where('projectSlug', '==', projectSlug)
            .where('mode', '==', modeKey)
            .where('style', '==', styleKey)
            .get()
          modeCounts[modeKey].styles[styleKey].counts = snapshot.size
        } catch (e) {
          console.log(e)
          error = e
        }
      }
    }
    response = modeCounts
  } catch (e) {
    console.log(e)
    error = e
  }

  return { response, error }
}

export async function getProjectTokensTotalByAttributes(projectSlug) {
  let response
  let error
  try {
    let filter = {
      round: {
        counts: 0,
        value: {},
      },
      word: {
        counts: 0,
        value: {},
      },
      font: {
        counts: 0,
        value: {},
      },
      type: {
        counts: 0,
        value: {},
      },
      style: {
        counts: 0,
        value: {},
      },
    }

    const word = await getAllWordsByProperty(projectSlug, 'word')
    const round = await getAllWordsByProperty(projectSlug, 'round')
    const type = await getAllWordsByProperty(projectSlug, 'type')
    const font = await getAllWordsByProperty(projectSlug, 'font')
    const style = await getAllWordsByProperty(projectSlug, 'style')

    filter = createStructureByProperty(filter, round, 'round')
    filter = createStructureByProperty(filter, word, 'word')
    filter = createStructureByProperty(filter, type, 'type')
    filter = createStructureByProperty(filter, font, 'font')
    filter = createStructureByProperty(filter, style, 'style')

    response = filter
  } catch (e) {
    console.log(e)
    error = e
  }

  return { response, error }
}

export async function getEscalerasProjectTokensTotalByAttributes(projectSlug) {
  let response
  let error
  try {
    let filter = {
      density: {
        counts: 0,
        value: {},
      },
      palette: {
        counts: 0,
        value: {},
      },
    }

    const density = await getAllTokensByProperty(projectSlug, 'density')
    const palette = await getAllTokensByProperty(projectSlug, 'palette')
    filter = createStructureByProperty(filter, density, 'density')
    filter = createStructureByProperty(filter, palette, 'palette')
    response = filter
  } catch (e) {
    console.log(e)
    error = e
  }

  return { response, error }
}

const createStructureByProperty = (filter, values, property) => {
  let content = {}

  for (let index = 0; index < values.uniques.length; index++) {
    let counts = {}
    const word = values.uniques[index]
    counts = countOccurrencesByitem(values)
    content[word] = { counts: counts[word] }
  }
  filter[property].value = content
  filter[property].counts = values.list.length

  return filter
}

const countOccurrencesByitem = (values) => {
  const counts = {}
  for (const element of values.list) {
    counts[element] = counts[element] ? counts[element] + 1 : 1
  }
  return counts
}

export const getProjectTokensPaginated = async (
  projectSlug,
  lastVisible,
  queryPaginated,
  sortingField,
  sortingDirection,
  tokenAttrs,
  isApiFilter
) => {
  try {
    if (isApiFilter) {
      const params = {
        projectSlug: projectSlug,
        lastVisible: lastVisible,
        queryPaginated: queryPaginated,
        sortingField: sortingField,
        sortingDirection: sortingDirection,
        tokenAttrs: JSON.stringify(tokenAttrs),
      }

      const filteredTokens = await axios.get(
        process.env.VUE_APP_CLOUD_FUNCTIONS_BASE_URL + '/api/filter',
        { params }
      )

      return filteredTokens.data
    } else {
      let query = tokensRef.where('projectSlug', '==', projectSlug)
      if (tokenAttrs && tokenAttrs.length) {
        let sortedTokenAttrs = tokenAttrs.sort((a, b) =>
          a.queryIndex < b.queryIndex ? 1 : -1
        )
        sortedTokenAttrs.forEach((attr) => {
          query = query.where(attr.name, '==', attr.value)
        })
      }

      if (sortingField) {
        query = query.orderBy(sortingField, sortingDirection)
      }

      if (lastVisible) {
        query = query.startAfter(lastVisible)
      }

      if (queryPaginated) {
        query = query.limit(queryPaginated)
      }
      const snapshot = await query.get()
      return await snapshot.docs.map((doc) => doc.data())
    }
  } catch (e) {
    console.error('An error ocurred while retrieving the tokens from DB.', e)
    throw e
  }
}

const getMode = (modeName) => {
  const keys = Object.keys(MODES)
  let result

  keys.forEach((key) => {
    const mode = MODES[key]
    if (mode.name === modeName) {
      result = { key, mode }
    }
  })

  return result
}

const getStyle = (styleName, modeStyles) => {
  const keys = Object.keys(modeStyles)
  let result

  keys.forEach((key) => {
    const style = modeStyles[key]
    if (style.name === styleName) {
      result = { key, style }
    }
  })

  return result
}

export const mapTokenModeStyle = (jsonData) => {
  const tokenJsonData = JSON.parse(jsonData)
  const tokenStyle = tokenJsonData.attributes.find(
    (el) => el['trait_type'] == 'Style'
  ).value

  const modeName = tokenStyle.split(' ')[0]
  const styleName = tokenStyle.split(' ')[1]
  const { key: modeKey, mode } = getMode(modeName)
  const { key: styleKey } = getStyle(styleName, mode.styles)

  return { mode: modeKey, style: styleKey }
}

export const validateTokenAttributes = (jsonData) => {
  let isValid = false

  const tokenJsonData = JSON.parse(jsonData)

  if (tokenJsonData.attributes) {
    isValid = true
  }

  return isValid
}

export const retrieveAssetsFromOpensea = async (
  claimableCollections,
  walletAddress,
  cursor,
  assetsData
) => {
  let response
  let error
  let claimableCollectionsQuery = ''
  for (let i = 0; i < claimableCollections.length; i++) {
    claimableCollectionsQuery = claimableCollectionsQuery.concat(
      `asset_contract_addresses=${claimableCollections[i]}&`
    )
  }
  claimableCollectionsQuery = claimableCollectionsQuery.substring(
    0,
    claimableCollectionsQuery.length - 1
  )
  let assets = assetsData || []
  const options = {
    method: 'GET',
    url: `${process.env.VUE_APP_OPENSEA_API_ASSETS_URL}?${claimableCollectionsQuery}`,
    params: {
      owner: walletAddress,
      order_direction: 'desc',
      include_orders: 'false',
      cursor: cursor,
      limit: 20,
    },
    headers: {
      accept: 'application/json',
      'X-API-KEY': process.env.VUE_APP_OPENSEA_API_KEY,
    },
  }
  try {
    const { data } = await axios.request(options)
    const newAssets = data.assets.reduce((acc, obj) => {
      const existingObj = acc.find(
        (asset) =>
          asset.address.toLowerCase() ===
          obj.asset_contract.address.toLowerCase()
      )
      if (existingObj) {
        existingObj.tokens.push(obj.token_id)
      } else {
        const newObj = {
          address: obj.asset_contract.address,
          tokens: [obj.token_id],
        }
        acc.push(newObj)
      }
      return acc
    }, assets)
    if (data.next) {
      return await retrieveAssetsFromOpensea(
        claimableCollections,
        walletAddress,
        data.next,
        newAssets
      )
    } else {
      response = newAssets
    }
  } catch (e) {
    console.log('error fetching opensea' + e)
    error = e
  }
  return { response, error }
}
